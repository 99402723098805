import { css } from "@emotion/core"

// font-size: calc(12px + 0.35vw; /* Responsive base font size */
// line-height: calc(12px + 1.05vw); /* Responsive Vertical Rhythm */

export const GlobalStyles = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    line-height: calc(12px + 1.05vw); /* Responsive Vertical Rhythm */
    font-size: calc(14px + .4vw);
    position: relative;
  }
  body {
    font-family: "Source Sans Pro", sans-serif;
  }
  input,
  button,
  textarea {
    font-family: "Source Sans Pro", sans-serif;
  }
  p {
    font-weight: 300;
    margin: 1rem 0;
    line-height: 1.78;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Libre Baskerville", serif;
  }

  h1 {
    font-size: 3.412rem;
    line-height: calc(45px + 2.3vw); /* Responsive Vertical Rhythm */
  }
  h2 {
    font-size: 2.416rem;
    line-height: calc(18px + 3vw); /* Responsive Vertical Rhythm */
  }

  h3 {
    font-size: 2.171rem;
    line-height: calc(18px + 2.2vw); /* Responsive Vertical Rhythm */
  }

  h4 {
    font-size: 1.3rem;
  }
  h5 {
    font-size: 1.243rem;
  }
  h6 {
    font-size: 1.132rem;
  }

  h4,
  h5,
  h6 {
    line-height: calc(18px + 0.2vw); /* Responsive Vertical Rhythm */
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: calc(12px + 1.05vw) 0; /* Responsive margins */
  }
  ul {
    list-style: none;
  }
  a {
    text-decoration: none;
  }
`
