import React from "react"
import { Helmet } from "react-helmet"
import Favicon from "../images/favicon.ico"
const Seo = () => {
  return (
    <Helmet htmlAttributes={{ lang: "en" }}>
      <title>
        Whatsdev | Learn Web Development, Land a job or your first consulting client
      </title>
      <meta
        name="description"
        content="Learn to code, land your first dev job or start a six figure consulting business."
      />
      <meta charset="utf-8" />
      <meta name="author" content="Tenzin" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="icon" href={Favicon} />
    </Helmet>
  )
}

export default Seo
