import React, { Component, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import LogoImg from "../images/logo.png"
import { Link } from "gatsby"
import { theme } from "../Styles/theme"
import Brand from "./brand";
import BurgerMenu from './burgerMenu'
import CollapseMenu from './collapseMenu'
import { useSpring, animated, config } from "react-spring"

const MainNav = (props) => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleNavbar = () => {
    console.log(navbarOpen)
    console.log("🔥")
    setNavbarOpen(!navbarOpen);
  }

  const barAnimation = useSpring({
    from: { transform: 'translate3d(0, -10rem, 0)' },
    transform: 'translate3d(0, 0, 0)',
  });

  const linkAnimation = useSpring({
    from: { transform: 'translate3d(0, 30px, 0)', opacity: 0 },
    to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
    delay: 800,
    config: config.gentle,
  });

  return (
    <>
      <Navbar style={barAnimation}>
        <NavbarContent>
          <Brand />
          <NavbarMenu id="menu" style={linkAnimation}>
            <NavbarMenuItem>
              {/* <Link css={NavbarMenuLink} to="/">
                Home
              </Link> */}
            </NavbarMenuItem>
            {/* <NavbarMenuItem>
            <NavbarMenuLink href="/freeCourse" primary>
              Free Course
            </NavbarMenuLink>
          </NavbarMenuItem> */}
          <NavbarMenuItem> 
              <a
                css={NavbarMenuLink}
                href="https://tally.so/r/w5jxOo?transparentBackground=1"
                rel="noopener noreferrer"
                target="_blank"
              >
              The Accelerator
              </a>
              {/* <a css={NavbarMenuLink} data-tally-open="w5jxOo" data-tally-emoji-text="👋" data-tally-emoji-animation="wave">The Accelerator</a> */}
            </NavbarMenuItem> 
           <NavbarMenuItem> 
              <a
                css={NavbarMenuLink}
                href="https://forms.gle/Gjq8nRNsEDeTHpzx6"
                rel="noopener noreferrer"
                target="_blank"
              >
              Sponsorships
              </a>
            </NavbarMenuItem> 
            
           <NavbarMenuItem> 
              <a
                css={NavbarMenuLink}
                href="http://www.jabjabjavascript.co"
                rel="noopener noreferrer"
                target="_blank"
              >
                JabJabJavascript.co
              </a>
            </NavbarMenuItem> 
            {/* <NavbarMenuItem>
              <Link css={NavbarMenuLink} to="/freelancing-optin">
                $100k Freelancing
              </Link>
            </NavbarMenuItem>  */}
            <NavbarMenuItem>
              <Link css={NavbarMenuLink} to="/js-course-free">
                Free Javascript Course
              </Link>
            </NavbarMenuItem> 
            {/* <NavbarMenuItem>
              <Link css={NavbarMenuLink} to="/work-with-me">
                Coaching
              </Link>
            </NavbarMenuItem> */}
            {/* <NavbarMenuItem>
              <Link css={NavbarMenuLink} to="/triple-threat">
                Dev Accelerator Coaching
              </Link>
            </NavbarMenuItem> */}
          </NavbarMenu>
          <BurgerWrapper>
            <BurgerMenu
              navbarState={navbarOpen}
              handleNavbar={handleNavbar}
            ></BurgerMenu>
          </BurgerWrapper>
        </NavbarContent>
      </Navbar>
      <CollapseMenu navbarState={navbarOpen} handleNavbar={handleNavbar} />
    </>
  )
}

export default MainNav

const Navbar = styled.nav`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: ${props => props.theme.sizes.navbarHeight};
  background: #fff;
  z-index: 9999;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.mobile}) {
        padding: 1rem 1.5rem;
  }
`

const NavbarContent = styled.div`
  margin: auto;
  width: 97%;
  max-width: 98%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const NavbarMenu = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`

const NavbarMenuItem = styled.li`
  margin-left: 3rem;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;

  link::after,
  a::after {
    transition: 0.3s all ease;
    position: absolute;
    content: "";
    left: 0;
    bottom: -3px;
  }

  link:hover::after,
  a:hover::after {
    color: black;
    width: 100%;
    z-index: -1;
    bottom: 4px;
    height: 35%;
    background-color: #e6f1f5;
  }
`

const NavbarMenuLink = css`
  color: black;
  font-size: ${theme.text.text200};
  font-family: "Oswald", sans-serif;
`

const BurgerWrapper = styled.div`
  margin: auto 0;
  @media (min-width: 769px) {
    display: none;
  }
`

