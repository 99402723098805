import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useSpring, animated } from "react-spring"
import { theme } from './../Styles/theme'
import { Link } from "gatsby"

const CollapseMenu = props => {
  const { open } = useSpring({ open: props.navbarState ? 0 : 1 })
  console.log(props.navbarState)
  if (props.navbarState === true) {
    console.log('showing collapse menu')
    return (
      // <div style={{ height: '100vh', width: '100vw', background: 'red', zIndex: '999999'}}>
      //   hello
      // </div>
      <CollapseWrapper
        style={{
          transform: open
            .interpolate({
              range: [0, 0.2, 0.3, 1],
              output: [0, -20, 0, -200],
            })
            .interpolate(openValue => `translate3d(0, ${openValue}px, 0`),
        }}
      >

        <NavbarMenu id="menu">
          <NavbarMenuItem>
            {/* <a
              css={NavbarMenuLink}
              href="https://calendly.com/tenzin/work-with-me"
              rel="noopener noreferrer"
              target="_blank"
            >
              Book a call
            </a> */}
            <a css={NavbarMenuLink} data-tally-open="w5jxOo" data-tally-emoji-text="👋" data-tally-emoji-animation="wave">The Accelerator</a>
          </NavbarMenuItem>
          <NavbarMenuItem>
            <a
              css={NavbarMenuLink}
              href="https://forms.gle/Gjq8nRNsEDeTHpzx6"
              rel="noopener noreferrer"
              target="_blank"
            >
              Sponsorships
            </a>
          </NavbarMenuItem>
          <NavbarMenuItem onClick={props.handleNavbar}>
            <a
              css={NavbarMenuLink}
              href="http://www.jabjabjavascript.co"
              rel="noopener noreferrer"
              target="_blank"
            >
              JabJabJavascript.co
            </a>
          </NavbarMenuItem>
          {/* <NavbarMenuItem onClick={props.handleNavbar}>
            <Link css={NavbarMenuLink} to="/freelancing-optin">
              $100k Freelancing
            </Link>
          </NavbarMenuItem> */}
          <NavbarMenuItem onClick={props.handleNavbar}>
            <Link css={NavbarMenuLink} to="/js-course-free">
              Free Javascript Course
            </Link>
          </NavbarMenuItem>
        </NavbarMenu>
      </CollapseWrapper>
    )
  }
  return null
}

export default CollapseMenu

const NavbarMenu = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 99999;
`

const NavbarMenuItem = styled.li`
  cursor: pointer;
  width: 100%;
  padding: 2.4rem 1rem;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  transition: all 300ms linear 0s;
  link::after,
  a::after {
    transition: 0.3s all ease;
    position: absolute;
    content: "";
    left: 0;
    bottom: -3px;
  }
  :first-child {
    border-top: 1px solid #ddd;
  }
  border-bottom: 1px solid #ddd;
  link:hover::after,
  a:hover::after {
    color: black;
    width: 100%;
    z-index: -1;
    bottom: 4px;
    height: 35%;
    background-color: #e6f1f5;
  }
`

const NavbarMenuLink = css`
  font-size: 1.5rem;
  font-family: "Oswald", sans-serif;
  color: ${theme.colors.grey500};
`

const CollapseWrapper = styled(animated.div)`
  z-index: 999;
  background: ${theme.colors.bgSecondary};
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 4.5rem;
  overflow-y: hidden;
  left: 0;
  top: calc(${props => props.theme.sizes.navbarHeight});
`


