import React from "react"
import styled from "@emotion/styled"
import logo from "../images/icon.png"
import { css } from "@emotion/core"
import { Link } from "gatsby"

const Brand = () => {
  const LogoStyle = css`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 50%;
    }
  `
  return (
    <Link css={LogoStyle} to="/">
      <img src={logo} alt="logo and scroll to top" />
    </Link>
  )
}

export default Brand

