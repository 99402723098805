import React from "react"
import styled from "@emotion/styled"
import { theme } from "./../Styles/theme"

const BurgerMenu = props => {
  return (
    <Frag onClick={props.handleNavbar}>
      <Wrapper className={props.navbarState ? "wrapperOpen" : ""}>
        <Hamburger className={props.navbarState ? "open" : ""}>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
        </Hamburger>
        <div>MENU</div>
      </Wrapper>
    </Frag>
  )
}

export default BurgerMenu

// div {
//   width: 14px;
//   height: 2px;
//   background-color: black;
//   margin: 3px 10px 3px 0;
// }

const Frag = styled.div`
  .wrapperOpen {
    background: black;
    color: white;
    .open > span {
      background-color: white !important;
    }
  }
`

const Hamburger = styled.div`
  span {
    background: black;
    display: block;
    position: relative;
    width: 14px;
    height: 2px;
    margin: 3px 10px 3px 0;
    transition: all ease-in-out 0.2s;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${theme.colors.bgMain};
  padding: 0.4rem 0.9rem;
  font-size: 0.9rem;
  font-family: "oswald", sans-serif;

  &:hover {
    filter: brightness(95%);
    cursor: pointer;
  }

  .open {
    span:nth-child(2) {
      opacity: 0;
    }
    span:nth-child(3) {
      transform: rotate(45deg);
      top: -5px;
    }
    span:nth-child(1) {
      transform: rotate(-45deg);
      top: 5px;
    }
  }
`
