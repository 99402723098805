import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { theme } from "../Styles/theme"
const Footer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: ${props => props.theme.pm.pm200};
  background: #ffffff;
  bottom: 0;
  padding: 0 1.5rem;
  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    padding: 0;
  }
`

const FooterContent = styled.div`
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.grey100};
  font-size: ${props => props.theme.text.text100};
  color: ${props => props.theme.colors.grey200};
  font-family: "Source sans pro", sans-serif;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    flex-direction: column;
    padding: 0rem 0 2rem;
  }
`

const FooterCopy = styled.p`
  font-family: "Source sans pro", sans-serif;
  font-weight: 300;
  @media only screen and (max-width: ${theme.breakpoints.mobile}) {

  }
`

const FooterMenu = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  li {
    margin-left: ${props => props.theme.pm.pm200};
  }
  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
  }
`

const FooterLink = css`
  color: ${theme.colors.grey200};
`

export default function MainFooter() {
  return (
    <Footer>
      <FooterContent>
        <FooterCopy>Made with Gatsby and and some caffeine ☕ &copy;2023 Whatsdev.com</FooterCopy>
        <FooterMenu>
          <li>
            <Link css={FooterLink} to="/service" alt="terms of service">
              Terms Of Service
            </Link>
          </li>
          <li>
            <Link css={FooterLink} to="/privacy" alt="privacy policy">
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link css={FooterLink} to="/contact" alt="contact page">
              Contact
            </Link>
          </li>
        </FooterMenu>
      </FooterContent>
    </Footer>
  )
}
